import React from "react"
import png_logo from '../../images/logo@2x.png'

export default () => {
    return (
        <div style={styles.header}>
            <img className="w-[128px] h-[40px] ml-[24px]" src={png_logo}/>
        </div>
    )
}

const styles={
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 68,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        background: '#fff'
    }
}