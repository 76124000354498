import React from "react"

export default () => {
    return (
        <div style={styles.container}>
            <span style={styles.text}>2022 © Copyright  上海赛舵智能科技有限公司</span>
        </div>
    )
}

const styles={
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 88,
        background: '#F1F5FB'
    },
    text: {
        color: '#666',
        fontFamily: 'MicrosoftYaHei',
        fontSize: '14px',
        color: '#666'
    }
}